import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Grid from "@material-ui/core/Grid";

import Plumber from "../../assets/AfterHour/after-hour.svg";

import styles from "../../jss/productStyle.js";

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
  },
  section2: {
    padding: "70px 0",
  },
  paragraph2: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "poppins",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
    "@media screen and (min-width:1920px)": {
      marginTop: "10px",
    },
  },
  imageContainer: {
    padding: "0px 80px 0px 80px",
    marginTop: "40px",
    "@media screen and (max-width:500px)": {
      padding: "0px 60px 0px 60px",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "1",
    },
  },
  orderContainer: {
    "@media screen and (max-width:500px)": {
      order: "2",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "2",
    },
  },
});

export default function ProductSection() {
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        justify="center"
        alignContent="center"
        alignItems="center"
        spacing={4}
        className={classes.containerInfo}
      >
        <Grid item lg={3} xs={10} sm={5} md={5}>
          <div align="center" className={classes.imageContainer}>
            <Plumber alt="24-hour-plumber" height="100%" width="100%" />
          </div>
        </Grid>

        <Grid item xs={10} sm={10} md={10} lg={6}>
          <h2 className={classes.title}>Clogged Drain Snaking</h2>
          <p className={classes.paragraph2}>
            Our team provides 24 hour plumber services in Toronto. We help you
            identify plumbing deficiencies in your home. We can help you
            identify and solve any issues occurring within your home. We have
            extensive experienced with Kitec piping and installing new piping as
            per the latest code standards.
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
